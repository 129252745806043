var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              busy: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(total_items)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.items.length > 0
                      ? _c("i", { staticClass: "bi bi-box-fill text-success" })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.items.length) + " "),
                  ]
                },
              },
              {
                key: "cell(total_value)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(item.total_value)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(step)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-badge",
                      { attrs: { variant: _vm.setOrderStepColor(item) } },
                      [_vm._v(_vm._s(_vm.setOrderStep(item)))]
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(item.created_at)) + " "),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "orders-purchases-romaneio-view",
                            params: { uuid: row.item.uuid },
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EyeIcon", size: "16" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(expand)",
                fn: function (row) {
                  return [
                    _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      attrs: { icon: "ChevronDownIcon", size: "16" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toggleRow(row.item, row.index)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c("b-table", {
                      attrs: {
                        small: "",
                        items: row.item.items,
                        responsive: "",
                        fields: _vm.itemTableColumns,
                        "show-empty": "",
                        "empty-text": "Nenhum item associado",
                        "head-variant": "dark",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(purchaseOrder_total)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        item.purchaseOrder.total
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrder)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDate(
                                        item.purchaseOrder.created_at
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }