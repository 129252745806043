<template>
  <div>
    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(total_items)="{ item }">
          <i
            v-if="item.items.length > 0"
            class="bi bi-box-fill text-success"
          ></i>
          {{ item.items.length }}
        </template>
        <template #cell(total_value)="{ item }">
          {{ item.total_value | toCurrency }}
        </template>
        <template #cell(step)="{ item }">
          <b-badge :variant="setOrderStepColor(item)">{{
            setOrderStep(item)
          }}</b-badge>
        </template>
        <template #cell(created_at)="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template #cell(actions)="row">
          <router-link
            :to="{
              name: 'orders-purchases-romaneio-view',
              params: { uuid: row.item.uuid },
            }"
          >
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
            />
          </router-link>
        </template>
        <template #cell(expand)="row">
          <feather-icon
            icon="ChevronDownIcon"
            size="16"
            class="cursor-pointer"
            @click.stop="toggleRow(row.item, row.index)"
          />
        </template>
        <template #row-details="row">
          <b-table
            small
            :items="row.item.items"
            responsive
            :fields="itemTableColumns"
            show-empty
            empty-text="Nenhum item associado"
            head-variant="dark"
          >
            <template #cell(purchaseOrder_total)="{ item }">
              {{ item.purchaseOrder.total | toCurrency }}
            </template>
            <template #cell(purchaseOrder)="{ item }">
              {{ formatDate(item.purchaseOrder.created_at) }}
            </template>
          </b-table>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BForm,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import vSelect from "vue-select";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    BBadge,
    BDropdownDivider,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem,
    CustomPaginateTable,
    BForm,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      loading: true,
      userData: JSON.parse(localStorage.getItem("userData")),
      submitedFilter: false,
      filters: {
        id: "",
        company: "",
        document_type: "",
        document_number: "",
        status: "",
        period: "",
        step: "",
      },
      perPage: 50,
      currentPage: 1,
      optionsStatus: [],
      optionsSteps: [],
      optionsDocumentTypes: [],
      totalRows: 0,
      rowsTable: 0,
      itemTableColumns: [
        { key: "purchaseOrder.id", label: "Id", class: "text-center" },
        {
          key: "purchaseOrder.document_number",
          label: "Num Doc",
          class: "text-center",
        },
        {
          key: "purchaseOrder.description",
          label: "Descrição",
          class: "text-center",
        },
        {
          key: "purchaseOrder.installments",
          label: "Parcelas",
          class: "text-center",
        },
        {
          key: "purchaseOrder.installment_due",
          label: "Vencimento",
          class: "text-center",
        },
        {
          key: "purchaseOrder.supplier.company_name",
          label: "Fornecedor",
          class: "text-center",
        },
        { key: "purchaseOrder_total", label: "Total", class: "text-center" },
        {
          key: "purchaseOrder",
          label: "Criado em",
          class: "text-center",
        },
      ],
      tableColumns: [
        {
          key: "id",
          label: "ID",
          class: "text-center",
        },

        {
          key: "user.full_name",
          label: "Criado por",
          class: "text-center",
        },
        {
          key: "step",
          label: "Status",
          class: "text-center",
        },
        {
          key: "total_value",
          label: "Valor Total",
          class: "text-center",
        },
        {
          key: "bank.name",
          label: "Banco",
          class: "text-center",
        },
        {
          key: "bank.agency",
          label: "Agencia",
          class: "text-center",
        },
        {
          key: "bank.account_number",
          label: "Conta",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Criado em",
          class: "text-center",
        },
        {
          key: "total_items",
          label: "Itens",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
        {
          key: "expand",
          label: "",
          class: "text-center",
        },
      ],
      dataItems: [],
      itemsSelecteds: [],
      selectedItems: [],
      selectAllCheckbox: false,
    };
  },
  methods: {
    setOrderStep(item) {
      const oldStep = item.oldStepRelation ? item.oldStepRelation.name : "";
      const newStep = item.newStepRelation
        ? item.newStepRelation.name
        : "Pagamento";
      return `${oldStep} >> ${newStep}`;
    },
    setOrderStepColor(item) {
      const newStep = item.new_step;

      if (newStep === 1) {
        return "success";
      } else if (newStep === 2) {
        return "primary";
      } else if (newStep === 3) {
        return "info";
      } else {
        return "warning";
      }
    },
    toggleRow(row, index) {
      this.$set(
        this.dataItems[index],
        "_showDetails",
        !this.dataItems[index]._showDetails
      );
    },
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
    async getData() {
      try {
        this.loading = true;
        const filtersData = {
          limit: this.perPage,
          page: this.currentPage,
        };

        this.$store
          .dispatch("OrderPurchase/listMovements", filtersData)
          .then((data) => {
            if (data) {
              this.dataItems = data.data;
              this.rowsTable = data.data.length;
              this.totalRows = data.meta.total;
              this.filters.currentPage = data.meta.current_page;
            }
          });
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
};
</script>
